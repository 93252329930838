<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        :visible="visrecord"
        title="审核进度"
        @ok="oncanrecord"
        @cancel="oncanrecord"
      >
        <a-timeline>
          <a-timeline-item
            v-for="(item, index) in progresslist"
            :key="index"
            :color="
              item.operate == 1
                ? 'blue'
                : item.operate == 2
                ? 'green'
                : item.operate == 3
                ? 'red'
                : 'blue'
            "
          >
            {{ item.positionName }}审批
            <span v-show="item.auditorName != null"
              >{{ item.auditorName }}&nbsp;</span
            >
            <span v-show="item.auditorTime != null"
              >{{ item.auditorTime | time }}&nbsp;</span
            >
            <span>{{
              item.operate == 2 ? '通过' : item.operate == 3 ? '拒绝' : '待审批'
            }}</span>
          </a-timeline-item>
        </a-timeline>
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>招商实施</a-breadcrumb-item>
            <a-breadcrumb-item>意向合同</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == ''" class="Enter-top-right">
          <a-button
            v-show="permissionlist.indexOf('/business/intention:新增') > -1"
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增意向
          </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="visible == false"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          min-width: 1398px;
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['ci.state'].value"
                  v-if="i == '状态'"
                  placeholder="请选择铺位租赁状态"
                  style="width: 178px"
                >
                  <a-select-option value="0"> 未提交 </a-select-option>
                  <a-select-option value="1"> 已提交 </a-select-option>
                  <a-select-option value="2"> 生效中 </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '负责人'"
                  placeholder="请选择负责人"
                  style="width: 178px"
                  v-model="params['cu1.name'].value"
                >
                  <a-select-option v-for="item in userlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '签约人'"
                  placeholder="请选择签约人"
                  style="width: 178px"
                  v-model="params['cu2.name'].value"
                >
                  <a-select-option v-for="item in userlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-date-picker
                  style="width: 178px"
                  v-else-if="i == '签约日期'"
                  v-model="params['ci.sign_date'].value"
                />
                <a-input
                  v-else
                  style="width: 178px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
          <!-- <a-row>
            
          </a-row> -->
        </a-form>
      </a-layout-content>
      <a-modal
        title="选择模板"
        :visible="tempvisible"
        :confirm-loading="confirmLoading"
        @ok="handletempOk"
        @cancel="handletempCancel"
      >
        <a-form-model :model="form" :label-col="4" :wrapper-col="6">
          <a-form-model-item label="模板类型">
            <a-select
              placeholder="请选择模板"
              :filter-option="filterOption"
              v-model="templateId"
            >
              <a-select-option v-for="it in templist" :key="it.id">
                {{ it.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-layout-content
        style="background: #fff; min-width: 1398px; padding: 24px; margin: 0"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <a-table
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="Intentlist"
              :rowClassName="rowClassName"
            >
              <!-- <template slot="opertion" slot-scope="text, record">
            <a @click="onPayment(record)">{{
              record.paymentId == null ? "生成" : "查看"
            }}</a>
          </template> -->
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
              <span slot="status" slot-scope="text">
                <a-badge
                  :status="text | statusTypeFilter"
                  :text="text | statusFilter"
                />
              </span>
              <template slot="action" slot-scope="text, record">
                <a-dropdown>
                  <a
                    v-show="
                      record.state != 2 &&
                      permissionlist.indexOf('/business/intention:修改') > -1
                    "
                    @click="e => e.preventDefault()"
                  >
                    操作
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item v-show="record.state == 0">
                      <a
                        v-show="
                          permissionlist.indexOf('/business/intention:修改') >
                          -1
                        "
                        @click="onEdit(record)"
                        href="javascript:;"
                        >{{ record.state == 0 ? '编辑' : '' }}</a
                      >
                    </a-menu-item>
                    <a-menu-item v-show="record.state == 0">
                      <a
                        @click.prevent="onchangestate(record)"
                        href="javascript:;"
                        >提交</a
                      >
                    </a-menu-item>
                    <a-menu-item v-show="record.state == 1">
                      <a
                        @click.prevent="onchangestate(record)"
                        href="javascript:;"
                        >撤销提交
                      </a>
                    </a-menu-item>
                    <a-menu-item v-show="record.state == 0">
                      <a-popconfirm
                        title="你确定要作废该条意向么?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="ondiscards(record)"
                        @cancel="cancel"
                      >
                        <a href="javascript:;">作废 </a>
                      </a-popconfirm>
                    </a-menu-item>
                    <a-menu-item v-show="record.state == 3">
                      <a
                        @click.prevent="onchangestate(record)"
                        href="javascript:;"
                        >重新编辑
                      </a>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
                <a
                  v-show="
                    record.state == 0 &&
                    permissionlist.indexOf('/business/intention:修改') > -1
                  "
                  @click="ontemp(record.id)"
                  >生成合同&nbsp;&nbsp;</a
                >
                <a
                  v-show="
                    record.state == 0 &&
                    permissionlist.indexOf('/business/intention:修改') > -1
                  "
                  @click="ondata(record)"
                  >上传合同&nbsp;&nbsp;</a
                >
                <a
                  v-show="
                    permissionlist.indexOf('/business/intention:修改') > -1 &&
                    record.state == 0
                  "
                  @click="oncontract(record)"
                  >补充资料&nbsp;&nbsp;</a
                >
                <a v-show="record.state != 0" @click="ondata(record)"
                  >查看合同&nbsp;&nbsp;</a
                >
                <a v-show="record.state != 0" @click="oncontract(record)"
                  >查看资料&nbsp;&nbsp;</a
                >
                <a
                  v-show="
                    permissionlist.indexOf('/business/intention:查询') > -1 ||
                    permissionlist.indexOf('/business/intention:查询下级') >
                      -1 ||
                    permissionlist.indexOf('/business/intention:查询同级') >
                      -1 ||
                    permissionlist.indexOf('/business/intention:查询全部') > -1
                  "
                  @click="onAudit(record)"
                  >审核记录 &nbsp;</a
                >
                <a v-show="record.state == 1" @click="onrecords(record)"
                  >审核进度</a
                >
                <!-- <a @click="onlook(record)">详情</a>&nbsp;&nbsp; -->
                <!-- <a v-show="record.state == 2" @click="onrelet(record.id, 3)"
                    >续租</a
                  >&nbsp;&nbsp;
                  <a
                    v-show="record.state == 2"
                    @click="showDrawer(record.id, 4)"
                    >退租</a
                  > -->
              </template>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
          <a-modal
            title="审核"
            :visible="auditvisible"
            @ok="onauditsub"
            @cancel="onauditcancel"
          >
            <a-form-model-item label="备注">
              <a-input placeholder="请输入备注" v-model="submitNote" />
            </a-form-model-item>
            <a-upload
              action=""
              :multiple="true"
              :file-list="submitAttach"
              :customRequest="handleauditinfo"
              @change="handleauditinfos"
            >
              <a-button> <a-icon type="upload" /> 点击上传 </a-button>
            </a-upload>
          </a-modal>
        </div>
        <div>
          <a-modal
            v-if="showsub == 2"
            :width="1000"
            title="审核记录"
            :visible="visibless"
            :confirm-loading="confirmLoading"
            @cancel="Close"
          >
            <a-table
              rowKey="id"
              :pagination="false"
              :columns="column"
              :data-source="Intentionaudit"
              :rowClassName="rowClassName"
            >
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
            </a-table>
          </a-modal>
          <a-drawer
            v-else
            :width="400"
            :title="title"
            placement="right"
            :closable="false"
            :visible="visibless"
            @close="Close"
          >
            <a-upload-dragger
              v-show="showsub == 0"
              :multiple="true"
              :file-list="agreement"
              :disabled="lookdisabled"
              action=""
              :customRequest="handlefile"
              @preview="handlePreview"
              @change="handlefiles"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">点击上传</p>
              <p class="ant-upload-hint">
                支持单个或批量上传。严格禁止 上传公司数据或其他波段文件
              </p>
            </a-upload-dragger>
            <a-upload-dragger
              v-show="showsub == 1"
              :multiple="true"
              :disabled="lookdisabled"
              :file-list="files"
              action=""
              :customRequest="handleChangeinfo"
              @preview="handlePreview"
              @change="handleChangeinfos"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">点击上传</p>
              <p class="ant-upload-hint">
                支持单个或批量上传。严格禁止 上传公司数据或其他波段文件
              </p>
            </a-upload-dragger>
            <div class="save-draw">
              <a-button
                type="primary"
                :disabled="lookdisabled"
                @click="subdata"
              >
                提交
              </a-button>
              <a-button
                @click="
                  () => {
                    this.visibless = false
                  }
                "
                style="margin-left: 30px"
              >
                取消
              </a-button>
            </div>
          </a-drawer>
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 编辑页 -->
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-layout-content
          :style="{
            background: '#fff',
            margin: 0,
          }"
        >
          <div class="content-title">
            <span>基础信息</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <a-form-model-item prop="talkId" class="ant--input" label="洽谈">
              <a-select
                style="width: 100%"
                @change="onclient"
                v-model="form.talkId"
              >
                <a-select-option v-for="item in talklist" :key="item.id">
                  {{ item.reason }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="waitDate"
              prop="waitDate"
              class="ant--input"
              label="等待期(天)"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入等待期"
                v-model="form.waitDate"
              />
            </a-form-model-item>
            <a-form-model-item class="ant--input" label="签约日期">
              <a-date-picker style="width: 100%" v-model="form.signDate">
                <template slot="renderExtraFooter"> </template>
              </a-date-picker>
            </a-form-model-item>
            <a-form-model-item prop="signBy" class="ant--input" label="签约人">
              <a-select
                placeholder="请选择签约人"
                style="width: 100%"
                :filter-option="filterOption"
                v-model="form.signBy"
              >
                <a-select-option v-for="item in userlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              prop="belongTo"
              class="ant--input"
              label="负责人"
            >
              <a-select
                placeholder="请选择负责人"
                style="width: 100%"
                :filter-option="filterOption"
                v-model="form.belongTo"
              >
                <a-select-option v-for="item in userlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="type" class="ant--input" label="租赁类型">
              <a-select
                default-value="铺位"
                v-model="form.type"
                @change="ontalktypeId"
                style="width: 100%"
              >
                <a-select-option value="0"> 铺位</a-select-option>
                <a-select-option value="1"> 多经点位</a-select-option>
                <a-select-option value="2"> 广告位</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              prop="businessTypeIds"
              class="ant--input"
              label="业态"
            >
              <a-cascader
                :field-names="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
                style="width: 100%"
                v-model="form.businessTypeIds"
                :options="BusinessTypes"
                placeholder="请选择业态"
                change-on-select
                @change="onChangetype"
              />
            </a-form-model-item>

            <a-form-model-item style="width: 25%" label="品牌">
              <a-select
                show-search
                :value="form.brandId"
                placeholder="品牌不能为空"
                style="width: 100%"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearchs"
                @change="handleChanges"
              >
                <a-select-option v-for="d in datalist" :key="d.value">
                  {{ d.text }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="term"
              prop="term"
              style="width: 12%"
              label="租期(月)"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入租期"
                v-model="form.term"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="deposit"
              prop="deposit"
              style="width: 13%"
              label="意向金(元)"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入意向金"
                v-model="form.deposit"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="depositLimit"
              prop="depositLimit"
              style="width: 25%"
              label="意向金限期"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入意向金限期"
                v-model="form.depositLimit"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="intentionLimit"
              prop="intentionLimit"
              style="width: 25%"
              label="意向等待期"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入意向等待期"
                v-model="form.intentionLimit"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="code"
              prop="code"
              style="width: 25%"
              label="编号"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入编号"
                v-model="form.code"
              />
            </a-form-model-item>
          </div>
        </a-layout-content>
        <a-layout-content style="background: #fff; margin-top: 24px">
          <div class="content-title">
            <span>合同主体</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <a-form-model-item
              prop="partyAId"
              class="ant--input"
              label="出租方"
            >
              <a-select
                :filter-option="filterOption"
                @change="onclientA"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                style="width: 100%"
                v-model="form.partyAId"
              >
                <a-select-option v-for="it in Enterpriselist" :key="it.id">
                  {{ it.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="partyAName"
              prop="partyAName"
              class="ant--input"
              label="出租方名称"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入出租方名称"
                v-model="form.partyAName"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="partyAContact"
              prop="partyAContact"
              class="ant--input"
              label="出租方联系电话"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入出租方联系电话"
                v-model="form.partyAContact"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="partyAAddress"
              prop="partyAAddress"
              class="ant--input"
              label="出租方联系地址"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入通讯地址"
                v-model="form.partyAAddress"
              />
            </a-form-model-item>
            <a-form-model-item
              prop="partyBId"
              class="ant--input"
              label="承租方"
            >
              <a-select
                :filter-option="filterOption"
                @change="onclientB"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                style="width: 100%"
                v-model="form.partyBId"
              >
                <a-select-option v-for="it in Clientlist" :key="it.id">
                  {{ it.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="partyBName"
              prop="partyBName"
              class="ant--input"
              label="承租方名称"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入承租方名称"
                v-model="form.partyBName"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="partyBContact"
              prop="partyBContact"
              class="ant--input"
              label="承租方联系电话"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入承租方联系电话"
                v-model="form.partyBContact"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="partyBAddress"
              prop="partyBAddress"
              class="ant--input"
              label="承租方联系地址"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入承租方联系地址"
                v-model="form.partyBAddress"
              />
            </a-form-model-item>
          </div>
        </a-layout-content>
        <a-layout-content style="background: #fff; margin-top: 24px">
          <div class="content-title">
            <span>租赁信息</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <a-form-model-item
              ref="rentPrice"
              prop="rentPrice"
              class="ant--input"
              label="租金"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入租金"
                v-model="form.rentPrice"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="rentUnit"
              prop="rentUnit"
              class="ant--input"
              label="租金单位"
            >
              <a-select
                :filter-option="filterOption"
                v-model="form.rentUnit"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
              >
                <a-select-option value="0"> 元/m².天 </a-select-option>
                <a-select-option value="5"> 元/m².月 </a-select-option>
                <a-select-option value="6"> 元/日 </a-select-option>
                <a-select-option value="1"> 元/月 </a-select-option>
                <a-select-option value="3"> 元/年 </a-select-option>
                <a-select-option value="4"> 元/m² </a-select-option>
                <a-select-option value="2"> 元 </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="propertyPrice"
              prop="propertyPrice"
              class="ant--input"
              label="物业费"
            >
              <a-input
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入租金"
                v-model="form.propertyPrice"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="propertyUnit"
              prop="propertyUnit"
              class="ant--input"
              label="物业费单位"
            >
              <a-select
                :filter-option="filterOption"
                v-model="form.propertyUnit"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
              >
                <a-select-option value="0"> 元/m².天 </a-select-option>
                <a-select-option value="5"> 元/m².月 </a-select-option>
                <a-select-option value="6"> 元/日 </a-select-option>
                <a-select-option value="1"> 元/月 </a-select-option>
                <a-select-option value="3"> 元/年 </a-select-option>
                <a-select-option value="4"> 元/m² </a-select-option>
                <a-select-option value="2"> 元 </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-layout-content>
      </a-form-model>
      <a-drawer
        width="590"
        title="添加铺位"
        :placement="right"
        :closable="false"
        :visible="visibles"
        @close="onclose"
      >
        <div class="search-wrop-left">
          <div class="search-left-project">
            <div class="search-left-top">项目</div>
            <div style="margin-top: 10px">
              <div class="back-search">
                {{ projectName }}
              </div>
            </div>
          </div>
          <div class="search-left-build">
            <div class="search-left-top">楼栋</div>
            <div style="margin-top: 10px">
              <div
                v-for="(t, i) in buildlist"
                :key="t.id"
                @click="buildChange(t, i)"
                :class="isbuild == i ? 'back-search' : 'search-bottom'"
              >
                {{ t.name }}
              </div>
            </div>
          </div>
          <div class="search-left-floor">
            <div class="search-left-top">楼层</div>
            <div style="margin-top: 10px">
              <div
                v-for="(t, i) in floorlist"
                :key="t.id"
                @click="floorChange(t, i)"
                :class="isfloor == i ? 'back-search' : 'search-bottom'"
              >
                {{ t.name }}
              </div>
            </div>
          </div>
          <div class="search-left-stoor">
            <div class="search-left-top">
              <input
                class="input-checkBox"
                type="checkbox"
                v-model="checkAll"
              />
              &nbsp;&nbsp;铺位
            </div>
            <div style="margin-top: 10px">
              <div
                v-for="(t, i) in storelist"
                :key="t.id"
                @click="storeChange(t, i)"
                :class="isstore == i ? 'back-search' : 'search-bottom'"
              >
                <input
                  :disabled="
                    t.state == 1
                      ? 'disabled'
                      : t.rentState == 1
                      ? 'disabled'
                      : t.locked == 1
                      ? 'disabled'
                      : false
                  "
                  type="checkbox"
                  @click="onchecked(t.id)"
                  class="input-checkBox"
                  :value="t.id"
                  v-model="checkStore"
                />&nbsp;&nbsp;
                {{ t.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="save-draw">
          <a-button
            v-if="store.state == 1 || store.rentState == 1 || store.locked == 1"
            disabled="disabled"
            type="primary"
          >
            保存1
          </a-button>
          <a-button v-else type="primary" @click="handleOk"> 保存 </a-button>
          <a-button @click="onclose" style="margin-left: 30px"> 取消 </a-button>
        </div>
      </a-drawer>
      <!-- 价格 -->
      <a-layout-content
        style="background: #fff; margin-top: 24px; min-height: 200px"
      >
        <div class="content-title">
          <span>租赁列表</span>
        </div>
        <div
          style="overflow: auto; overflow-y: hidden"
          class="content-body flex-d-row"
        >
          <div
            v-for="(item, index) in form.stores"
            :key="item.name"
            style="position: relative"
          >
            <div
              style="line-height: 65px; margin-right: 10px"
              class="body-building flex-d-column"
              @click="Addmobile(item, index)"
            >
              <span>{{ item.storeName }}</span>
            </div>
            <span @click="deletestore(index)" class="deletestore">x</span>
            <div class="deletestore" style="left: 5px; width: 20px">
              <a-icon
                v-if="item.locked == 1"
                style="font-size: 11px; z-index: 10"
                type="lock"
              />
              <a-icon
                v-else
                style="font-size: 11px; z-index: 10"
                type="unlock"
              />
            </div>
          </div>
          <div @click="Addmobile(undefined)" class="body-building">+</div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
        text-align: center;
        position: fixed;
        bottom: 0;
      "
    >
      <div save-draw>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '出租方',
    dataIndex: 'partyAName',
  },
  {
    title: '承租方',
    dataIndex: 'partyBName',
    // scopedSlots: { customRender: "state" },
  },
  {
    title: '负责人',
    dataIndex: 'belongToName',
  },
  {
    title: '签约人',
    dataIndex: 'signByName',
  },
  {
    title: '签约日期',
    dataIndex: 'signDate',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  //   {
  //     title: "收款信息",
  //     dataIndex: "paymentId",
  //     scopedSlots: { customRender: "opertion" },
  //   },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
]
const column = [
  {
    title: '审批人',
    dataIndex: 'auditorName',
  },
  {
    title: '审批时间',
    dataIndex: 'time',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '状态',
    dataIndex: 'note',
  },
]

const statusMap = {
  0: {
    status: 'warning',
    text: '未提交',
  },
  1: {
    status: 'processing',
    text: '已提交',
  },
  2: {
    status: 'success',
    text: '生效中',
  },
  3: {
    status: 'error',
    text: '已作废',
  },
}
let timeout
function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  async function fake() {
    try {
      const res = await http.getNativelist(value)
      //   console.log(res.data.data);
      const sult = res.data.data.brand
      const brand = []
      sult.forEach(item => {
        brand.push({
          value: item.id,
          text: item.brandName,
        })
      })
      callback(brand)
    } catch (ex) {
      console.log('error:' + ex)
    }
  }
  timeout = setTimeout(fake, 300)
}
const base64 = require('js-base64').Base64
const OSS = require('ali-oss')
let client
const api = process.env.VUE_APP_API_BASE_URL
import { nanoid } from 'nanoid'
import http from '../../../http'
export default {
  data() {
    return {
      permissionlist: [],
      files: [],
      agreement: [],
      textlist: ['出租方', '承租方', '负责人', '签约人', '签约时间', '状态'],
      contactindex: undefined,
      lookdisabled: false,
      projectlist: [],
      floorlist: [],
      buildlist: [],
      storelist: [],
      typelist: [],
      Intentionaudit: [],
      Clientlist: [],
      Enterpriselist: [],

      isproject: 0,
      isbuild: 0,
      isfloor: 0,
      isstore: 0,
      datalist: [],
      paymentlist: [],
      columns,
      column,
      visible: false,
      logvisible: false,
      titles: '',
      tempvisible: false,
      auditvisible: false,
      visibles: false,
      visibless: false,
      BusinessTypes: [],
      placement: 'right',
      labelCol: { span: 17 },
      wrapperCol: { span: 24 },
      projectName: '',
      linkId: '',
      submitNote: '',
      submitAttach: [],
      params: {
        current: 1,
        pageSize: 10,
        'pe.name': {
          value: '',
          op: '%like%',
        },
        'cc.name': {
          value: '',
          op: '%like%',
        },
        'cu1.name': {
          value: undefined,
          op: '=',
        },
        'cu2.name': {
          value: undefined,
          op: '=',
        },
        'ci.sign_date': {
          value: '',
          op: '=',
        },
        'ci.state': {
          value: undefined,
          op: '=',
        },
      },
      total: 0,
      userlist: [],
      talklist: [],
      Intentlist: [],
      selectedRowKey: [],
      Id: '',
      title: '',
      storesmoth: '0',
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      checked: false,
      visrecord: false,
      progresslist: [],
      checkStore: [],
      checkStores: [],
      store: {
        rentType: '0',
        dayRent: '',
        monthRent: '',
        salesIn: '',
        sales: '',
        rateIn: '',
        rate: '',
        storeName: '',
        storeId: '',
        locked: '',
      },
      showsub: 0,
      businessType: [
        '1448114380591370242',
        '1448114414225494017',
        '1448114445020073985',
      ],
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      templateId: '1555386016093212673',
      templist: [],
      form: {
        id: '',
        code: '',
        Integer: '',
        deposit: '',
        waitDate: '',
        type: '',
        businessType: '',
        brandId: '',
        rentPrice: '',
        rentUnit: '0',
        propertyPrice: '',
        propertyUnit: '0',
        depositLimit: '',
        intentionLimit: '',
        belongTo: '',
        talkId: '',
        partyAId: '',
        partyAName: '',
        partyAContact: '',
        partyAAddress: '',
        state: '',
        partyBId: '',
        projectId: '',
        partyBName: '',
        partyBContact: '',
        partyBAddress: '',
        businessTypeIds: [],
        agreement: [],
        signDate: '',
        signBy: '',
        files: [],
        stores: [],
      },
      rules: {
        code: [
          {
            required: true,
            message: '编号不能为空',
            trigger: 'blur',
          },
        ],
        rentPrice: [
          {
            required: true,
            message: '租金不能为空',
            trigger: 'blur',
          },
        ],
        rentUnit: [
          {
            required: true,
            message: '租金单位不能为空',
            trigger: 'change',
          },
        ],
        propertyPrice: [
          {
            required: true,
            message: '物业费不能为空',
            trigger: 'blur',
          },
        ],
        propertyUnit: [
          {
            required: true,
            message: '物业费单位不能为空',
            trigger: 'change',
          },
        ],
        depositLimit: [
          {
            required: true,
            message: '意向金限期不能为空',
            trigger: 'blur',
          },
        ],
        intentionLimit: [
          {
            required: true,
            message: '意向等待期不能为空',
            trigger: 'blur',
          },
        ],
        talkId: [
          {
            required: true,
            message: '洽谈不能为空',
            trigger: 'change',
          },
        ],
        waitDate: [
          {
            required: true,
            message: '等待期不能为空',
            trigger: 'blur',
          },
        ],
        signBy: [
          {
            required: true,
            message: '签约人不能为空',
            trigger: 'change',
          },
        ],
        belongTo: [
          {
            required: true,
            message: '负责人不能为空',
            trigger: 'change',
          },
        ],
        type: [
          {
            required: true,
            message: '租赁类型不能为空',
            trigger: 'change',
          },
        ],
        term: [
          {
            required: true,
            message: '租期不能为空',
            trigger: 'blur',
          },
        ],
        deposit: [
          {
            required: true,
            message: '意向金不能为空',
            trigger: 'blur',
          },
        ],
        businessTypeIds: [
          {
            required: true,
            message: '业态不能为空',
            trigger: 'change',
          },
        ],
        partyAId: [
          {
            required: true,
            message: '出租方不能为空',
            trigger: 'blur',
          },
        ],
        partyAName: [
          {
            required: true,
            message: '出租方名称不能为空',
            trigger: 'change',
          },
        ],
        partyAContact: [
          {
            required: true,
            message: '联系电话格式错误或为空',
            trigger: 'change',
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            len: 11,
          },
        ],
        partyAAddress: [
          {
            required: true,
            message: '通讯地址不能为空',
            trigger: 'change',
          },
        ],
        partyBId: [
          {
            required: true,
            message: '承租方不能为空',
            trigger: 'change',
          },
        ],
        partyBName: [
          {
            required: true,
            message: '承租方名称不能为空',
            trigger: 'change',
          },
        ],
        partyBContact: [
          {
            required: true,
            message: '联系电话格式错误或为空',
            trigger: 'change',
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            len: 11,
          },
        ],
        partyBAddress: [
          {
            required: true,
            message: '通讯地址不能为空',
            trigger: 'change',
          },
        ],
      },
    }
  },
  filters: {
    statusFilter(type) {
      if (type == null) {
        return ' '
      }
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      if (type == null) {
        return ' '
      }
      return statusMap[type].status
    },
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['pe.name'].value = values.出租方
        this.params['cc.name'].value = values.承租方
      })
      this.getIntention()
    },
    handleReset() {
      this.fom.resetFields()
    },
    async handlePreview(file) {
      //   console.log(file.url);
      let urls = file.url.replaceAll(
        'sccncloud.oss-cn-shanghai.aliyuncs.com',
        'yunfiles.shangchan.cn'
      )
      if (file.url) {
        var url = urls //要预览文件的访问地址
        const u = 'https://preview.shangchan.cn/preview'
        window.open(
          u + '/onlinePreview?url=' + encodeURIComponent(base64.encode(url))
        )
      }
    },
    toggle() {
      this.expand = !this.expand
    },
    handleChangeinfo(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      //   console.log(info);
      this.putObject(
        1,
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data,
        info.file.name
      )
    },
    handleSearchs(value) {
      fetch(value, brand => (this.datalist = brand))
    },
    handleChanges(value) {
      this.form.brandId = value
      fetch(value, brand => (this.datalist = brand))
    },
    handleChangeinfos({ fileList }) {
      if (this.files.length > fileList.length) {
        this.files = fileList
      }
      this.form.files = JSON.stringify(fileList)
    },
    handlefile(info) {
      //   console.log(info);
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      //   console.log(info);
      this.putObject(
        2,
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data,
        info.file.name
      )
    },
    handlefiles({ fileList }) {
      if (this.agreement.length > fileList.length) {
        this.agreement = fileList
      }
      this.form.agreement = JSON.stringify(fileList)
    },
    async putObject(i, client, id, name, data, realName) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        if (i == 1) {
          a.forEach(item => {
            this.files.push({
              bucket: 'sccncloud2',
              name: item.name,
              realName: realName,
              uid: id,
              status: 'done',
              url: url,
            })
          })
          this.form.files = JSON.stringify(this.files)
        } else if (i == 2) {
          a.forEach(item => {
            this.agreement.push({
              bucket: 'sccncloud2',
              name: item.name,
              realName: realName,
              uid: id,
              status: 'done',
              url: url,
            })
          })
          this.form.agreement = JSON.stringify(this.agreement)
        } else if (i == 3) {
          //审核
          a.forEach(item => {
            this.submitAttach.push({
              bucket: 'sccncloud2',
              name: item.name,
              realName: realName,
              type: name,
              uid: id,
              status: 'done',
              url: url,
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleOk() {
      if (this.contactindex == undefined) {
        this.checkStores.forEach(it => {
          this.form.stores.push(it)
        })
        // console.log(this.checkStores);
      } else {
        // console.log(this.contactindex);
        this.form.stores.splice(this.contactindex, 1, this.checkStores[0])
      }
      //   console.log(this.form.stores);
      this.onclose()
    },
    onclose() {
      this.visibles = false
      this.checkStore = []
      this.checkStores = []
      this.store = [
        {
          rentType: '0',
          dayRent: '',
          monthRent: '',
          salesIn: '',
          sales: '',
          rateIn: '',
          storeName: '',
          rate: '',
          storeId: '',
        },
      ]
    },
    Close() {
      this.visibless = false
    },
    ondata(e) {
      //合同 0
      //   console.log(e);
      this.visibless = true
      this.showsub = 0
      this.form.id = e.id
      if (e.agreement.length > 0) {
        this.agreement = JSON.parse(e.agreement)
        var a = []
        this.agreement.forEach(item => {
          let url
          url = client.signatureUrl(item.name)
          a.push({
            bucket: 'sccncloud2',
            name: item.name,
            realName: item.realName,
            uid: nanoid(),
            status: 'done',
            url: url,
          })
        })
        this.agreement = a
      }
      if (e.state != 0) {
        this.lookdisabled = true
        this.title = '查看合同'
      } else {
        this.title = '上传合同'
      }
    },
    oncontract(e) {
      //补充资料 1
      this.visibless = true
      this.showsub = 1
      this.form.id = e.id
      var a = []
      this.files.forEach(item => {
        let url
        url = client.signatureUrl(item.name)
        a.push({
          bucket: 'sccncloud2',
          name: item.name,
          realName: item.realName,
          uid: nanoid(),
          status: 'done',
          url: url,
        })
      })
      this.files = a
      if (e.state != 0) {
        this.lookdisabled = true
        this.title = '查看资料'
      } else {
        this.title = '补充资料'
      }
    },
    subdata() {
      if (this.showsub == 0) {
        //合同
        this.intentionagreement(this.form.id, JSON.stringify(this.agreement))
      } else {
        //补充资料
        this.intentionfile(this.form.id, JSON.stringify(this.files))
      }
      this.visibless = false
    },
    onchecked(id) {
      //   console.log(id);
      let index = this.checkStore.findIndex(item => item == id)
      if (index == -1) {
        this.checkstorelist(id)
      } else {
        this.checkStores.splice(index, 1)
      }
    },
    async checkstorelist(id) {
      try {
        const res = await http.getStoreinfo(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.checkStores.push({
            storeId: data.store.id,
            storeName: data.store.name,
            rentType: data.store.rentState,
            dayRent: data.store.dayStandardFee,
          })
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getStoreinfo(id) {
      try {
        const res = await http.getStoreinfo(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.store = data.store
          this.store.storeId = data.store.id
          this.store.storeName = data.store.name
          this.store.rentType = data.store.rentState + ''
          this.store.dayRent = data.store.dayStandardFee
          this.store.locked = data.store.locked
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    Addmobile(e, index) {
      if (e == undefined) {
        this.contactindex = undefined
      } else {
        this.contactindex = index
        // console.log(e);
        this.store = e
        this.store.rentType = e.rentType + ''
      }
      this.visibles = true
    },
    //业态
    onChangetype(e) {
      this.form.businessType = e[e.length - 1]
      //   console.log(e);
    },
    //洽谈类型
    ontalktypeId(e) {
      if (e != 0) {
        this.form.stores = []
        this.storetype('1426030017037049857', this.form.talkType)
      }
    },
    //收款信息    开始
    onPayment(item) {
      if (item.paymentId == null) {
        this.payment.linkId = item.id
        this.visibles = true
        this.titles = '生成收款信息'
      } else {
        this.visibles = true
        this.titles = '查看收款信息'
        this.getIntentPaymentId(item.id)
      }
    },
    ClosePayment() {
      this.visibles = false
      this.payment.id = ''
      this.payment.linkId = ''
      this.payment.note = ''
      this.payment.paymentInfoId = ''
    },
    Changepayment(e) {
      this.payment.paymentInfoId = e
      //   console.log(e);
    },
    ontemp(e) {
      this.form.id = e
      this.gettemplatelist()
    },
    //确定生成模板
    handletempOk() {
      setTimeout(() => {
        this.tempvisible = false
      }, 100)
      window.open(
        `${api}business/intention/generate/${this.templateId}/${this.form.id}`
      )
    },
    //取消
    handletempCancel() {
      this.tempvisible = false
      this.templateId = ''
      this.templist = []
      this.form.id = ''
    },
    async gettemplatelist() {
      try {
        const res = await http.gettemplatelist(0)
        const { success, data } = res.data
        if (success) {
          this.templist = data.templates
          this.templateId = data.templates[0].id
          this.tempvisible = true
        }
      } catch (ex) {
        this.$message.warning(ex)
        console.log('error:', ex)
      }
    },

    //
    async getIntentPaymentId(id) {
      try {
        const res = await http.getIntentPaymentId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.payment.id = data.payment.id
          this.payment.note = data.payment.note
          this.payment.paymentInfoId = data.payment.paymentInfoId
        }
        this.timer()
      } catch (ex) {
        this.$message.warning(ex)
        console.log('error:', ex)
      }
    },

    onAudit(e) {
      this.visibless = true
      this.showsub = 2
      this.auditIntention(e.id)
    },
    // 删除
    // onDetele() {
    //   this.deleteIntention();
    // },
    // async deleteIntention() {
    //   try {
    //     const res = await http.deleteIntention(this.selectedRowKey);
    //     const { success, msg } = res.data;
    //     if (success) {
    //       this.$message.success(msg);
    //     } else {
    //       this.$message.warning(msg);
    //     }
    //     this.timer();
    //   } catch (ex) {
    //     console.log("error:" + ex);
    //   }
    // },
    onClose() {
      this.visible = false
      this.Id = ''
      this.form.businessTypeIds = []
      this.files = []
      this.agreement = []
      this.form = {
        id: '',
        Integer: '',
        deposit: '',
        waitDate: '',
        type: '',
        businessTypeId: '',
        belongTo: '',
        partyAId: '',
        partyAName: '',
        code: '',
        brandId: '',
        rentPrice: '',
        rentUnit: '',
        propertyPrice: '',
        propertyUnit: '',
        depositLimit: '',
        intentionLimit: '',
        partyAContact: '',
        partyAAddress: '',
        partyBId: '',
        projectId: '',
        partyBName: '',
        partyBContact: '',
        signDate: '',
        signBy: '',
        partyBAddress: '',
        agreement: [],
        files: [],
        stores: [],
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          //   console.log("submit!", this.form);
          if (typeof this.form.agreement == 'object') {
            this.form.agreement = JSON.stringify(this.form.agreement)
          }
          if (typeof this.form.files == 'object') {
            this.form.files = JSON.stringify(this.form.files)
          }
          if (this.Id == '') {
            this.AddIntention(this.form)
          } else if (this.Id != '') {
            this.putIntention(this.form)
          }
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    // 编辑
    onEdit(item) {
      this.Id = item.id
      this.form.id = item.id
      //   console.log(this.form.id);
      this.visible = true
      this.title = '编辑'
      this.getIntentionId(item.id)
      this.getuserlist()
      fetch('', brand => (this.datalist = brand))
      let users = JSON.parse(localStorage.getItem('store-userlist'))
      this.form.projectId = users.projectInfo.projectId
      this.projectName = users.projectInfo.projectName
      this.buildingid(this.form.projectId)
    },
    async putIntention(form) {
      try {
        const res = await http.putIntention(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    // 新增
    async AddIntention(form) {
      try {
        const res = await http.AddIntention(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.onClose()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },

    onpagesize(e) {
      this.params.current = e
      this.getIntention()
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
      this.getuserlist()
      let users = JSON.parse(localStorage.getItem('store-userlist'))
      this.form.belongTo = users.id + ''
      this.form.projectId = users.projectInfo.projectId
      this.projectName = users.projectInfo.projectName
      fetch('', brand => (this.datalist = brand))
      this.buildingid(this.form.projectId)
    },
    async getIntention() {
      try {
        const res = await http.getIntention(this.params)
        // console.log(res);
        const { success, data } = res.data
        if (success) {
          this.Intentlist = data.intentions.records
          this.total = data.intentions.total
          //   console.log(data.intentions);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getIntentionId(id) {
      try {
        const res = await http.getIntentionId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.form = data.intention
          this.form.brandId =
            data.intention.brandId == null ? '' : data.intention.brandId + ''
          this.form.rentUnit =
            data.intention.rentUnit == null ? '' : data.intention.rentUnit + ''
          this.form.propertyUnit =
            data.intention.propertyUnit == null
              ? ''
              : data.intention.propertyUnit + ''
          this.form.type = data.intention.type + ''
          //   setTimeout(() => {
          //     if (data.intention.files == null) {
          //       this.files = [];
          //     } else {
          //       this.files = JSON.parse(data.intention.files);
          //     }
          //     var a = [];
          //     this.files.forEach((item) => {
          //       let url;
          //       url = client.signatureUrl(item.name);
          //       a.push({
          //         bucket: "sccncloud2",
          //         name: item.name,
          //         realName: item.realName,
          //         uid: nanoid(),
          //         status: "done",
          //         url: url,
          //       });
          //     });
          //     console.log(this.files);
          //     this.files = a;
          //     this.agreement = JSON.parse(data.intention.agreement);
          //     var b = [];
          //     this.agreement.forEach((item) => {
          //       let url;
          //       url = client.signatureUrl(item.name);
          //       b.push({
          //         bucket: "sccncloud2",
          //         name: item.name,
          //         realName: item.realName,
          //         uid: nanoid(),
          //         status: "done",
          //         url: url,
          //       });
          //     });
          //     this.agreement = b;
          //   }, 100);
          //   console.log(this.agreement);
          this.form.businessTypeIds = data.intention.businessTypeIds
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async intentionfile(id, file) {
      try {
        const res = await http.intentionfile(id, file)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    }, //合同
    async intentionagreement(id, file) {
      try {
        const res = await http.intentionagreement(id, file)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    handleauditinfo(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      this.putObject(
        3,
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data,
        info.file.name
      )
    },
    handleauditinfos({ fileList }) {
      if (this.submitAttach.length > fileList.length) {
        this.submitAttach = fileList
      }
    },
    onchangestate(e) {
      this.auditvisible = true
      this.form.state = e.state
      this.linkId = e.id
    },
    onauditsub() {
      //   console.log(this.form.state);
      let sub = JSON.stringify(this.submitAttach)
      if (this.form.state == 0) {
        this.intentionsubmit(sub)
      } else if (this.form.state == 1) {
        this.intentioncancel(sub)
      } else {
        this.intentionreset(sub)
      }
    },
    onauditcancel() {
      this.linkId = ''
      this.submitNote = ''
      this.submitAttach = []
      this.auditvisible = false
    },
    async intentionsubmit(submitAttach) {
      try {
        const res = await http.intentionsubmit({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.onauditcancel()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
        console.log('ex:', ex)
      }
    },
    onrecords(e) {
      this.visrecord = true
      this.getauditrecord(1, e.id, e.processId, e.version)
    },
    oncanrecord() {
      this.visrecord = false
      setTimeout(() => {
        this.progresslist = []
      }, 100)
    },
    async getauditrecord(linkType, linkId, processId, version) {
      try {
        const res = await http.getauditrecord(
          linkType,
          linkId,
          processId,
          version
        )
        const { success, data } = res.data
        if (success) {
          this.progresslist = data.record
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    ondiscards(e) {
      this.getintendiscard(e.id)
    },
    //作废
    async getintendiscard(id) {
      try {
        const res = await http.getintendiscard(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.timer()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
        console.log('ex:', ex)
      }
    },
    async intentioncancel(submitAttach) {
      try {
        const res = await http.intentioncancel({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.onauditcancel()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
        console.log('ex:', ex)
      }
    },
    async intentionreset(submitAttach) {
      try {
        const res = await http.intentionreset({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.onauditcancel()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
        console.log('ex:', ex)
      }
    },
    async getuserlist() {
      try {
        const res = await http.getuserlist()
        const { success, data } = res.data
        if (success) {
          this.userlist = data.users
          //   console.log("userlist", data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //洽谈列表
    async gettalklist() {
      try {
        const res = await http.gettalklist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.talklist = data.talks
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //审核记录
    async auditIntention(id) {
      try {
        const res = await http.auditIntention(id)
        const { success, data } = res.data
        if (success) {
          this.Intentionaudit = data.audits
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //Change事件
    properyChange(t, i) {
      this.isproject = i
      this.buildingid(t.id)
    },
    buildChange(t, i) {
      this.isbuild = i
      this.floorid(t.id)
    },
    floorChange(t, i) {
      this.isfloor = i
      this.storeid(t.id)
    },
    storeChange(t, i) {
      this.getStoreinfo(t.id)
      this.isstore = i
    },
    onclientA() {
      //   console.log(this.form.partyAId);
      this.getenterpriseid(this.form.partyAId)
    },
    async getenterpriseid(id) {
      try {
        const res = await http.getenterpriseid(id)
        const { success, data } = res.data
        if (success) {
          this.form.partyAName = data.enterprise.name
          this.form.partyAContact = data.enterprise.contactMobile
          this.form.partyAAddress = data.enterprise.address
        }
      } catch (e) {
        console.log(e)
      }
    },
    onclientB() {
      //   console.log(this.form.partyBId);
      this.getClientId(this.form.partyBId)
    },
    async getClientId(id) {
      try {
        const res = await http.getClientId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.form.partyBName = data.client.name
          var mobil = ''
          data.client.contacts.forEach((item, index) => {
            if (index == 0) {
              mobil = item.mobile
            } else {
              mobil = mobil + ',' + item.mobile
            }
          })
          //   console.log(mobil);
          this.form.partyBContact = mobil
          this.form.partyBAddress = data.client.infoRegLocation
        }
      } catch (e) {
        console.log(e)
      }
    },
    deletestore(e) {
      this.form.stores.splice(e, 1)
      this.onclose()
    },
    async project() {
      try {
        const res = await http.project()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.projectlist = data.projects
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.buildlist = data.buildings
          if (data.buildings.length == 0) {
            this.floorlist = []
          } else {
            this.floorid(this.buildlist[0].id)
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.floorlist = data.floors
          this.storeid(data.floors[0].id)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async storeid(floorId) {
      try {
        const res = await http.storeid(floorId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.storelist = data.stores
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async BusinessTypelist() {
      try {
        const res = await http.BusinessTypelist()
        const { success, data } = res.data
        if (success) {
          data.businessTypes.forEach(i => {
            if (i.children.length > 0) {
              i.children.forEach(i => {
                if (i.children.length > 0) {
                  i.children.forEach(i => {
                    if (i.children.length > 0) {
                      i.children.forEach(() => {
                        // console.log(i);
                        this.$message.error('业态底层')
                      })
                    } else {
                      i.children = undefined
                    }
                  })
                } else {
                  i.children = undefined
                }
              })
            } else {
              i.children = undefined
            }
          })
          this.BusinessTypes = data.businessTypes

          //   console.log(data.businessTypes);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getClientlist() {
      try {
        const res = await http.getClientlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.Clientlist = data.clients
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getenterpriselist() {
      try {
        const res = await http.getenterpriselist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.Enterpriselist = data.enterprises
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getIntention()
      }, 500)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    async type() {
      try {
        const res = await http.type()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.typelist = data.propertyTypes
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
  },
  mounted() {
    this.getIntention()
    this.gettalklist()
    this.project()
    this.type()
    this.getsts()
    this.getuserlist()
    this.permissionlist = JSON.parse(
      localStorage.getItem('store-userlist')
    ).permissions
    let users = JSON.parse(localStorage.getItem('store-userlist'))
    this.form.projectId = users.projectInfo.projectId
    this.projectName = users.projectInfo.projectName
    this.buildingid(this.form.projectId)
    this.BusinessTypelist()
    this.getClientlist()
    this.getenterpriselist()
    this.form.signBy =
      JSON.parse(localStorage.getItem('store-userlist')).id + ''

    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    count() {
      return this.expand ? 7 : 5
    },
    checkAll: {
      get() {
        return this.checkStore.length === this.storelist.length
      },
      set(val) {
        if (val) {
          this.checkStore = []
          this.checkStores = []
          this.storelist.forEach(storelist => {
            if (storelist.state == 1 || storelist.rentState == 1) {
              console.log()
            } else {
              this.checkStore.push(storelist.id)
              this.checkstorelist(storelist.id)
            }
          })
        } else {
          this.checkStore = []
          this.checkStores = []
        }
      },
    },
  },
}
</script>
<style lang="less" scoped>
.price {
  position: absolute;
  right: 1px;
  bottom: -6px;
  width: 70px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.search-wrap {
  width: 100%;
  /* background: #999;*/
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 630px;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.ant-btn {
  margin: 0px 8px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.ant-layout {
  height: 100%;
}
.code-box-demo .ant-slider {
  margin-bottom: 16px;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.deletestore {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: -2px;
  z-index: 10;
}
.deletestore:hover {
  cursor: pointer;
  color: rgb(197, 172, 29);
}
.search-wrop-left {
  height: 450px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  .search-left-project,
  .search-left-build,
  .search-left-floor {
    border-right: 1px solid #d9d9d9;
    min-width: 130px;
    width: 25%;
    overflow-y: auto;
    height: 100%;
  }
  .search-bottom {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #fff;
  }
  .search-bottom:hover {
    background-color: #f5f5f5;
  }
  .search-left-top {
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #f0f1f4;
  }
  .search-left-stoor {
    min-width: 130px;
    width: 25%;
    height: 100%;
    overflow-y: auto;
  }
}
.price-formitem {
  position: relative;
  width: 100%;
}
.back-search {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #f5f5f5;
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.content-body {
  padding: 24px;
  .ant--input {
    width: 25%;
  }
  .ant-form-item {
    height: 90px;
    margin: 0;
    padding-right: 20px;
  }
  .body-building {
    min-width: 65px;
    min-height: 65px;
    line-height: 65px;
    text-align: center;
    border: 1px dashed #999;
  }
  .body-building:hover {
    cursor: pointer;
    color: rgb(197, 172, 29);
    border: 1px dashed rgb(197, 172, 29);
  }
}
.save-draw {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-top: 1px solid #eaeaea;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.input-checkBox {
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
::v-deep .ant-form-item-label {
  text-align: left;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
